@import "src/styles/styles";

.home {
    flex: 1;
    width: 100%;
    height: 100%;
    position: relative;
}

.section {

}

.tickBlocks {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 36px 0;


    .tickBlock {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 18px;
        text-align: center;
        align-items: center;

        .tickCircle {
            position: relative;
            height: 63px;
            width: 63px;
            align-self: center;
        }

        .tickBlockText {
            padding-top: 9px;
            max-width: 180px;
            margin: 0 auto;
        }

    }
}
